.ql-bg-black {
    background-color: #000;
}

.ql-bg-red {
    background-color: #e60000;
}

.ql-bg-orange {
    background-color: #f90;
}

.ql-bg-yellow {
    background-color: #ff0;
}

.ql-bg-green {
    background-color: #008a00;
}

.ql-bg-blue {
    background-color: #06c;
}

.ql-bg-purple {
    background-color: #93f;
}

.ql-color-white {
    color: #fff;
}

.ql-color-red {
    color: #e60000;
}

.ql-color-orange {
    color: #f90;
}

.ql-color-yellow {
    color: #ff0;
}

.ql-color-green {
    color: #008a00;
}

.ql-color-blue {
    color: #06c;
}

.ql-color-purple {
    color: #93f;
}

.ql-font-serif {
    font-family: Georgia, Times New Roman, serif;
}

.ql-font-monospace {
    font-family: Monaco, Courier New, monospace;
}

.ql-size-small {
    font-size: 0.75em;
}

.ql-size-large {
    font-size: 1.5em;
}

.ql-size-huge {
    font-size: 2.5em;
}

.ql-align-center {
    text-align: center;
}

.ql-align-justify {
    text-align: justify;
}

.ql-align-right {
    text-align: right;
}
