// This site uses typekit fonts loaded via public/index.html
// <link rel="stylesheet" href="https://use.typekit.net/zhd1xsa.css" />

@font-face {
    font-family: 'icomoon';
    src: url('../assets/fonts/icomoon/icomoon.eot?');
    src: url('../assets/fonts/icomoon/icomoon.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/icomoon/icomoon.ttf') format('truetype'),
        url('../assets/fonts/icomoon/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../assets/fonts/OpenSans/OpenSans.woff2') format('woff2'), url('../assets/fonts/OpenSans/OpenSans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap; // sass-lint:disable-line no-misspelled-properties
}

@font-face {
    font-family: 'Open Sans';
    src: url('../assets/fonts/OpenSans/OpenSans-Semibold.woff2') format('woff2'), url('../assets/fonts/OpenSans/OpenSans-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap; // sass-lint:disable-line no-misspelled-properties
}

@font-face {
    font-family: 'Open Sans';
    src: url('../assets/fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'), url('../assets/fonts/OpenSans/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap; // sass-lint:disable-line no-misspelled-properties
}

%theme-font-standard {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
}

%theme-font-standard-bold {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

%theme-font-standard-heavy {
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
}

%theme-font-alternate {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

%theme-font-alternate-bold {
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
}
