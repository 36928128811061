@import "~bootstrap/scss/bootstrap-grid.scss";

.current-sweeps-modal {
    .sweeps-table {
        caption {
            font-size: 2rem;
            color: #000;
            caption-side: top;
            text-align: left;
            line-height: 1.5em;
        }
    }
}

.post-claim {
    .hidden {
        display: none;
    }

    .congratulations-message {
        margin: 0 auto;

        @include media-breakpoint-up(xs) {
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            width: 70%;
        }

        @include media-breakpoint-up(lg) {
            width: 50%;
        }

        @include media-breakpoint-up(xl) {
            width: 40%;
        }
    }

    .description {
        display: block;
        text-align: left;

        h2 {
            text-align: left;
        }
    }

    .claim-box {
        padding-bottom: 1em;
        margin: 0 auto;
        border: 1px solid #ccc;

        .promotion-name {
            margin-top: 0.625em;
        }

        .promo-image {
            width: 100%;
            height: auto;
        }

        @include media-breakpoint-up(xs) {
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            width: 70%;
        }

        @include media-breakpoint-up(lg) {
            width: 50%;
        }

        @include media-breakpoint-up(xl) {
            width: 40%;
        }
    }
}

.reveal-game-dialog {
    .reveal-game-modal {
        .interactive-reveal-iframe {
            width: 100%;
            min-height: 50vh;
        }
    }
}