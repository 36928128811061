// rounded corners
// .rc-<where>-<what size>
$radii: (
    'xs': '5px',
    'sm': '10px',
    'md': '5px',
    'lg': '40px',
    'full': '500px',
);

@each $name, $radius in $radii {
    .rc-all-#{$name} {
        border-radius: #{$radius};
    }
    .rc-tl-#{$name} {
        border-top-left-radius: #{$radius};
    }
    .rc-tr-#{$name} {
        border-top-right-radius: #{$radius};
    }
    .rc-bl-#{$name} {
        border-bottom-left-radius: #{$radius};
    }
    .rc-br-#{$name} {
        border-bottom-right-radius: #{$radius};
    }
    .rc-top-#{$name} {
        border-top-left-radius: #{$radius};
        border-top-right-radius: #{$radius};
    }
    .rc-bottom-#{$name} {
        border-bottom-left-radius: #{$radius};
        border-bottom-right-radius: #{$radius};
    }
}
