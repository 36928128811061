// colors
$white: #fff;
$black: #000;
$theme-green: #30842A;
$theme-dark-green: #2A7525;
$theme-light-green: #73C167;
$theme-grey: #ccc;
$theme-light-gray: #f0f1f0;
$theme-medium-gray: #adadad;
$theme-medium-dark-gray: #707070;
$theme-dark-gray: #2d3e4c;
$theme-darkest-gray: #111111;
$theme-purple: #524fa2;
$theme-light-blue: #2196f3;
$theme-blue: #065596;
$theme-dark-blue: #023674;
$theme-pink: #ca0a7e;
$theme-yellow: #d9982c;
$theme-gold: #fdbb30;
$theme-red: #e31837;
$theme-orange: #e3730f;

$theme-default: $theme-medium-gray;
$theme-default-light: $theme-light-gray;
$theme-default-lightest: #e6e6e6;
$theme-default-dark: $theme-dark-gray;
$theme-primary: $theme-orange;
$theme-primary-dark: #043965;
$theme-primary-light: #097fe0;
$theme-primary-lightest: #e3730f;
$theme-secondary: $theme-gold;
$theme-secondary-dark: #de7a28;
$theme-success: $theme-green;
$theme-success-dark: #106d16;
$theme-danger: $theme-red;
$theme-danger-dark: #b5132c;

$theme-primary-active: #E64E30;
$theme-secondary-active: #2A7525;

// utilities
$border-radius-sm: 2px;
$border-radius-md: 5px;
$border-radius-lg: 10px;

$primary-nav-breakpoint: 1175px;

// elements
$hero-bg: $theme-yellow;
$primary-nav-bg-hover: $theme-primary-lightest;
$primary-nav-bg-active: $white;
$primary-nav-text: $white;
$primary-nav-text-active: $theme-primary-active;
$content-box-border: $theme-default;
$logged-in-dashboard: $theme-danger-dark;

$mobile-nav-bg: #57a549;
$mobile-nav-bg-active: $theme-green;
$mobile-nav-text: $white;
$mobile-nav-text-active: $white;

$ticket-tooltip-color: $theme-light-blue;

$content-box-text: $theme-dark-green;
$content-box-background: $theme-light-gray;
$centent-box-outline: $theme-grey;
$content-box-radius: $border-radius-md;


// gradients
%theme-gradient-orange {
    background: rgb(249,164,55);
    background: -moz-linear-gradient(135deg, rgba(249,164,55,1) 0%, rgba(230,78,48,1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(249,164,55,1) 0%, rgba(230,78,48,1) 100%);
    background: linear-gradient(135deg, rgba(249,164,55,1) 0%, rgba(230,78,48,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f9a437",endColorstr="#e64e30",GradientType=1);
}

%theme-gradient-green {
    background: rgb(115,191,100);
    background: -moz-linear-gradient(135deg, rgba(115,191,100,1) 0%, rgba(55,134,42,1) 100%);
    background: -webkit-linear-gradient(135deg, rgba(115,191,100,1) 0%, rgba(55,134,42,1) 100%);
    background: linear-gradient(135deg, rgba(115,191,100,1) 0%, rgba(55,134,42,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#73bf64",endColorstr="#37862a",GradientType=1);
}

%theme-gradient-green-horizontal {
    background: rgb(115,191,100);
    background: -moz-linear-gradient(180deg, rgba(115,191,100,1) 0%, rgba(55,134,42,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(115,191,100,1) 0%, rgba(55,134,42,1) 100%);
    background: linear-gradient(180deg, rgba(115,191,100,1) 0%, rgba(55,134,42,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#73bf64",endColorstr="#37862a",GradientType=1);
}

%theme-gradient-orange-horizontal {
    background: rgb(249,164,55);
    background: -moz-linear-gradient(180deg, rgba(249,164,55,1) 0%, rgba(230,78,48,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(249,164,55,1) 0%, rgba(230,78,48,1) 100%);
    background: linear-gradient(180deg, rgba(249,164,55,1) 0%, rgba(230,78,48,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f9a437",endColorstr="#e64e30",GradientType=1);
}